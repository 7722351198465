<template>
    <div class="booking-now d-none d-md-block">
        <div class="container">
            <div class="row">
                <div class="col-12 col-sm-12 offset-md-7 col-md-5">
                    <div class="card">
                        <form class="card-body needs-validation" ref="formDates" novalidate 
                            @submit.prevent="submitDates">
                            <div class="row">
                                <div class="col-12" style="margin-top:20px; margin-bottom:10px">
                                    <span class="form-label" v-on:dblclick="setEdit(0)">
                                        {{translate.home.form[5]}}
                                    </span>
                                    <ic-informacion icon-color="#fe3a00"/>
                                </div>
                                <div class="col-12">
                                    <select name="select" class="form-select" required 
                                        v-model="booking.id_airport">
                                        <option disabled selected hidden value=null>Seleccione su Aeropuerto</option>
                                        <option v-for="item in airport" :key="item" 
                                            :value="item.id">{{item.name}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12" style="margin-top:20px; margin-bottom:10px">
                                    <span class="form-label" v-on:dblclick="setEdit(0)">
                                        {{translate.home.form[0]}}
                                    </span>
                                    <ic-informacion icon-color="#fe3a00"/>
                                </div>
                                <div class="col-12">
                                    <div class="row mb-3">
                                        <div class="col-6">
                                            <input type="date" class="form-control" :min="currentToday()" 
                                                name="start_date" required v-model="booking.start_date"/>
                                        </div>
                                        <div class="col-3">
                                            <select name="select" class="form-select" required 
                                                v-model="booking.start_hour">
                                                <option disabled selected hidden value=null>00h</option>
                                                <option v-for="item in 24" :key="item" 
                                                    :value="item">{{item < 10 ? "0"+item : item}}</option>
                                            </select>
                                        </div>
                                        <div class="col-3">
                                            <select name="select" class="form-select" required 
                                                v-model="booking.start_minute">
                                                <option disabled selected hidden value=null>00m</option>
                                                <option v-for="item in 60" :key="item" 
                                                    :value="(item-1) < 10 ? '0'+(item-1) : (item-1)">{{(item-1) < 10 ? "0"+(item-1) : (item-1)}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <select class="form-select" required
                                            v-model="booking.area_pickup_id" @change="onChangeAreaPickup">
                                            <option disabled selected hidden value="">Terminal de salida</option>
                                            <option v-for="(item, index) in areas" :key="index" 
                                                :value="item.id">{{item.name}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12" style="margin-top:20px; margin-bottom:10px">
                                    <span class="form-label" v-on:dblclick="setEdit(1)">
                                        {{translate.home.form[1]}}
                                    </span>
                                    <ic-informacion icon-color="#fe3a00"/>
                                </div>
                                <div class="col-12">
                                    <div class="row mb-3">
                                        <div class="col-6">
                                            <input type="date" class="form-control" :min="currentToday()" 
                                                name="end_date" required v-model="booking.end_date"/>
                                        </div>
                                        <div class="col-3">
                                            <select name="select" class="form-select" required 
                                                v-model="booking.end_hour">
                                                <option disabled selected hidden value=null>00h</option>
                                                <option v-for="item in 24" :key="item" 
                                                    :value="item">{{item < 10 ? "0"+item : item}}</option>
                                            </select>
                                        </div>
                                        <div class="col-3">
                                            <select name="select" class="form-select" required 
                                                v-model="booking.end_minute">
                                                <option disabled selected hidden value=null>00m</option>
                                                <option v-for="item in 60" :key="item" 
                                                    :value="(item-1) < 10 ? '0'+(item-1) : (item-1)">{{(item-1) < 10 ? "0"+(item-1) : (item-1)}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <select name="select" class="form-select" required 
                                            v-model="booking.area_delivery_id" @change="onChangeAreaDelivery">
                                            <option disabled selected hidden value="">Terminal de llegada</option>
                                            <option v-for="(item, index) in areas" :key="index" 
                                                :value="item.id">{{item.name}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12"  style="margin-top:20px">
                                <div class="row">
                                    <div class="col-4">
                                        <span class="form-label" v-on:dblclick="setEdit(2)">
                                            {{translate.home.form[2]}}
                                        </span>
                                    </div>
                                    <div class="col-2">
                                        <ic-bola-moto 
                                            @click="onSelectTypeVehicle(1)"
                                            :icon-color="(booking.type_vehicle_id == 1) ? '#FFFFFF' : '#FE3A00'"
                                            :icon-color-circle="(booking.type_vehicle_id == 1) ? '#FE3A00' : '#FFFFFF'"
                                        />
                                    </div>
                                    <div class="col-2">
                                        <ic-bola-coche
                                            @click="onSelectTypeVehicle(2)"
                                            :icon-color="(booking.type_vehicle_id == 2) ? '#FFFFFF' : '#FE3A00'"
                                            :icon-color-circle="(booking.type_vehicle_id == 2) ? '#FE3A00' : '#FFFFFF'"
                                        />
                                    </div>
                                    <div class="col-2">
                                        <ic-bola-furgoneta 
                                            @click="onSelectTypeVehicle(3)"
                                            :icon-color="(booking.type_vehicle_id == 3) ? '#FFFFFF' : '#FE3A00'"
                                            :icon-color-circle="(booking.type_vehicle_id == 3) ? '#FE3A00' : '#FFFFFF'"
                                        />
                                    </div>
                                    <div class="col-2">
                                        <ic-bola-autobus 
                                            @click="onSelectTypeVehicle(4)"
                                            :icon-color="(booking.type_vehicle_id == 4) ? '#FFFFFF' : '#FE3A00'"
                                            :icon-color-circle="(booking.type_vehicle_id == 4) ? '#FE3A00' : '#FFFFFF'"
                                        />
                                    </div>
                                </div>
                                <div class="text-danger" v-if="isValidTypeVehicle">
                                    Por favor seleccione su tipo de vehículo 
                                </div>
                            </div>
                            <div class="col-12"  style="margin-top:20px; margin-bottom: 5px">
                                <span class="form-label" v-on:dblclick="setEdit(3)">
                                    {{translate.home.form[3]}}
                                </span>
                            </div>
                            <div class="col-12">
                                <input type="text" placeholder="Escribe" class="form-control" 
                                    name="code_promotion" v-model="code_promotion"/>
                            </div>
                            <div class="col-12"  style="margin-top:20px">
                                <ButtonOrange 
                                    v-if="!isEditing"
                                    type="submit"
                                    :label="translate.home.form[4]"
                                />
                                <div v-else class="btn-booking" v-on:dblclick="setEdit(4)">{{translate.home.form[4]}}</div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row d-md-none" style="background-color: #ededed">
        <div class="col-12 col-sm-12 offset-md-7 col-md-5 pb-4">
            <form class="container needs-validation" ref="formDates" novalidate 
                @submit.prevent="submitDates">
                <div class="row">
                    <div class="col-12" style="margin-top:20px; margin-bottom:10px">
                        <span class="form-label" v-on:dblclick="setEdit(0)">
                            {{translate.home.form[0]}}
                        </span>
                        <ic-informacion icon-color="#fe3a00"/>
                    </div>
                    <div class="col-12">
                        <div class="row mb-3">
                            <div class="col-6">
                                <input type="date" class="form-control" :min="currentToday()" 
                                    name="start_date" required v-model="booking.start_date"/>
                            </div>
                            <div class="col-3">
                                <select name="select" class="form-select" required 
                                    v-model="booking.start_hour">
                                    <option disabled selected hidden value=null>00h</option>
                                    <option v-for="item in 24" :key="item" 
                                        :value="item">{{item < 10 ? "0"+item : item}}</option>
                                </select>
                            </div>
                            <div class="col-3">
                                <select name="select" class="form-select" required 
                                    v-model="booking.start_minute">
                                    <option disabled selected hidden value=null>00m</option>
                                    <option v-for="item in 60" :key="item" 
                                        :value="(item-1) < 10 ? '0'+(item-1) : (item-1)">{{(item-1) < 10 ? "0"+(item-1) : (item-1)}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <select class="form-select" required
                                v-model="booking.area_pickup_id">
                                <option disabled selected hidden value="">Terminal de salida</option>
                                <option v-for="(item, index) in areas" :key="index" 
                                    :value="item.id">{{item.name}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12" style="margin-top:20px; margin-bottom:10px">
                        <span class="form-label" v-on:dblclick="setEdit(1)">
                            {{translate.home.form[1]}}
                        </span>
                        <ic-informacion icon-color="#fe3a00"/>
                    </div>
                    <div class="col-12">
                        <div class="row mb-3">
                            <div class="col-6">
                                <input type="date" class="form-control" :min="currentDeliveryToday()" 
                                    name="end_date" required v-model="booking.end_date"/>
                            </div>
                            <div class="col-3">
                                <select name="select" class="form-select" required 
                                    v-model="booking.end_hour">
                                    <option disabled selected hidden value=null>00h</option>
                                    <option v-for="item in 24" :key="item" 
                                        :value="item">{{item < 10 ? "0"+item : item}}</option>
                                </select>
                            </div>
                            <div class="col-3">
                                <select name="select" class="form-select" required 
                                    v-model="booking.end_minute">
                                    <option disabled selected hidden value=null>00m</option>
                                    <option v-for="item in 60" :key="item" 
                                        :value="(item-1) < 10 ? '0'+(item-1) : (item-1)">{{(item-1) < 10 ? "0"+(item-1) : (item-1)}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <select name="select" class="form-select" required 
                                v-model="booking.area_delivery_id">
                                <option disabled selected hidden value="">Terminal de llegada</option>
                                <option v-for="(item, index) in areas" :key="index" 
                                    :value="item.id">{{item.name}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-12"  style="margin-top:20px">
                    <div class="row">
                        <div class="col-4">
                            <span class="form-label" v-on:dblclick="setEdit(2)">
                                {{translate.home.form[2]}}
                            </span>
                        </div>
                        <div class="col-2">
                            <ic-bola-moto 
                                @click="onSelectTypeVehicle(1)"
                                :icon-color="(booking.type_vehicle_id == 1) ? '#FFFFFF' : '#FE3A00'"
                                :icon-color-circle="(booking.type_vehicle_id == 1) ? '#FE3A00' : '#FFFFFF'"
                            />
                        </div>
                        <div class="col-2">
                            <ic-bola-coche
                                @click="onSelectTypeVehicle(2)"
                                :icon-color="(booking.type_vehicle_id == 2) ? '#FFFFFF' : '#FE3A00'"
                                :icon-color-circle="(booking.type_vehicle_id == 2) ? '#FE3A00' : '#FFFFFF'"
                            />
                        </div>
                        <div class="col-2">
                            <ic-bola-furgoneta 
                                @click="onSelectTypeVehicle(3)"
                                :icon-color="(booking.type_vehicle_id == 3) ? '#FFFFFF' : '#FE3A00'"
                                :icon-color-circle="(booking.type_vehicle_id == 3) ? '#FE3A00' : '#FFFFFF'"
                            />
                        </div>
                        <div class="col-2">
                            <ic-bola-autobus 
                                @click="onSelectTypeVehicle(4)"
                                :icon-color="(booking.type_vehicle_id == 4) ? '#FFFFFF' : '#FE3A00'"
                                :icon-color-circle="(booking.type_vehicle_id == 4) ? '#FE3A00' : '#FFFFFF'"
                            />
                        </div>
                    </div>
                    <div class="text-danger" v-if="isValidTypeVehicle">
                        Por favor seleccione su tipo de vehículo 
                    </div>
                </div>
                <div class="col-12"  style="margin-top:20px; margin-bottom: 5px">
                    <span class="form-label" v-on:dblclick="setEdit(3)">
                        {{translate.home.form[3]}}
                    </span>
                </div>
                <div class="col-12">
                    <input type="text" placeholder="Escribe" class="form-control" 
                        name="code_promotion" v-model="code_promotion"/>
                </div>
                <div class="col-12"  style="margin-top:20px">
                    <ButtonOrange 
                        type="submit"
                        v-if="!isEditing"
                        :label="translate.home.form[4]"
                    />
                    <div v-else class="btn-booking" v-on:dblclick="setEdit(4)">{{translate.home.form[4]}}</div>
                </div>
            </form>
        </div>
    </div>
    <b-modal v-model="showEdit" title="Editar Text" hide-footer>
        <div class="row">
            <div class="col-12">
                <input type="text" class="form-control p-2 mb-2" v-model="translate.home.form[indexEdit]">
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-6 text-center">
                        <b-button @click="onCloseEdit">Cancelar</b-button>
                    </div>
                    <div class="col-6 text-center">
                        <b-button @click="saveTranslate">Guardar</b-button>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import {mapState} from 'vuex'
import store from '../../store'
import ButtonOrange from "../ButtonOrange.vue"
import IcBolaMoto from '../Icons/IcBolaMoto.vue'
import IcBolaCoche from '../Icons/IcBolaCoche.vue'
import IcBolaAutobus from '../Icons/IcBolaAutobus.vue'
import IcInformacion from '../Icons/IcInformacion.vue'
import IcBolaFurgoneta from '../Icons/IcBolaFurgoneta.vue'

export default {
    name: 'FormDates',
    components: {
        IcBolaMoto,
        IcBolaCoche,
        ButtonOrange,
        IcBolaAutobus,
        IcInformacion,
        IcBolaFurgoneta,
    },
    data() {
        return {
            areas: [],
            airport: [],
            indexEdit: 0,
            validate: false,
            showEdit: false,
            booking: {
                id_airport: null,
                end_date: null,
                end_hour: null,
                start_date: null,
                start_hour: null,
                end_minute: null,
                area_pickup_id: "",
                start_minute: null,
                type_vehicle_id: 0,
                area_delivery_id: "",
                code_promotion: null,
                area_pickup: {
                    id: null,
                    name: null,
                },
                area_delivery: {
                    id: null,
                    name: null,
                },
            }
        }
    },
    computed: {
        isValidTypeVehicle() {
            return (this.validate && this.booking.type_vehicle_id == '');
        }
    },
    methods: {
        onChangeAreaPickup(event) {
            const areaId = event.target.value;
            const areaPickup = this.areas.find(element => element.id == areaId);
            this.booking.area_pickup = areaPickup;
        },
        onChangeAreaDelivery(event) {
            const areaId = event.target.value;
            const areaDelivery = this.areas.find(element => element.id == areaId);
            this.booking.area_delivery = areaDelivery;
        },
        currentToday() {
            const todayDate = moment().format("YYYY-MM-DD");
            return todayDate;
        },
        currentDeliveryToday() {
            if (this.booking.start_date != null) {
                const todayDeliveryDate = moment(this.booking.start_date).format("YYYY-MM-DD");
                return todayDeliveryDate;
            } else {
                const todayDeliveryDate = moment().format("YYYY-MM-DD");
                return todayDeliveryDate;
            }
        },
        setEdit(index) {
            console.log(index);
            if (this.isEditing) { 
                this.showEdit = true; 
                this.indexEdit = index;
            }
        },
        saveTranslate() {
            const self = this;
            store.dispatch('saveTranslate');
            self.showEdit = false;
        },
        onCloseEdit() {
            const self = this;
            self.showEdit = false;
        },
        submitDates() {
            if (this.$refs.formDates.checkValidity()) {
                const bookingJson = JSON.stringify(this.booking);
                localStorage.setItem('booking', bookingJson);
                this.$router.push('/parkings')
            }
        },
        onSelectTypeVehicle(value) {
            this.booking.type_vehicle_id = value;
        },
        getAreas() {
            const self = this;
            axios.get(`/areas/naranja/${this.company.id}`)
                .then(response => {
                    self.areas = response.data.data;
                }).catch(() => {
                    store.dispatch('setCompany', null);
                });
        }
    },
    mounted() {
        const self = this;

        console.log('currentToday', this.currentToday());

        this.getAreas();

        // Fetch all the forms we want to apply custom Bootstrap validation styles to
        const forms = document.querySelectorAll('.needs-validation')

        // Loop over them and prevent submission
        Array.from(forms).forEach(form => {
            form.addEventListener('submit', event => {
                if (!form.checkValidity()) {
                    event.preventDefault()
                    event.stopPropagation()
                }
                self.validate = true;
                form.classList.add('was-validated')
            }, false)
        });

        const valuesBooking = JSON.parse(localStorage.getItem('booking', null));

        if (valuesBooking) {
            self.booking.end_date = valuesBooking.end_date;
            self.booking.end_hour = valuesBooking.end_hour;
            self.booking.start_date = valuesBooking.start_date;
            self.booking.start_hour = valuesBooking.start_hour;
            self.booking.end_minute = valuesBooking.end_minute;
            self.booking.start_minute = valuesBooking.start_minute;
            self.booking.area_pickup_id = valuesBooking.area_pickup_id;
            self.booking.code_promotion = valuesBooking.code_promotion;
            self.booking.type_vehicle_id = valuesBooking.type_vehicle_id;
            self.booking.area_delivery_id = valuesBooking.area_delivery_id;
        } else {
            self.onSelectTypeVehicle(2);
        }

        axios.get(`/airports`)
            .then(response => {
                console.log(response.data.data);
                self.airport = response.data.data;
            }).catch(() => {
                console.error("Error")
            });
    },
    computed: {
        ...mapState(['translate', 'isEditing', 'company'])
    }
}
</script>

<style scoped>
.btn-booking {
    width: 100%;
    padding: 8px;
    color: white;
    font-weight: bold;
    text-align: center;
    border-radius: 20px;
    font-family: 'Raleway';
    text-transform: uppercase;
    border-color: transparent;
    background-color: #fe3a00;
}
.card {
    min-height: 344px;
    margin-bottom: 20px;
    border-radius: 20px;
    box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.15);
}

.card-body {
    margin-right: 20px;
    margin-left: 20px;
}

.form-label {
    color: #000;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    line-height: normal;
    font-stretch: normal;
    font-family: 'Raleway';
    letter-spacing: normal;
    text-transform: uppercase;
}

.card { 
    margin-top: 50px;
    background-color: rgb(255,255,255, .9);

}
.booking-now {
    z-index: 3;
    width: 100%;
    position:absolute;
    margin-top: -550px;
}

@media (max-width: 600px) {
    .card { 
        margin-top: 50px;
        background-color: rgb(255,255,255, .9);
    }

    .booking-now {
        z-index: 3;
        width: 100%;
        margin-top: 0px;
        position:relative;
    }
}
</style>
