<template>
    <div class="box-custom">
        <div class="container">
            <div class="row" style="height: 48px">
                <div class="col-6 label-header pt-3">
                    Resumen de tu reserva:
                </div>
                <div class="col-6 pt-2 text-right">
                    <div @click="onChangeShowDetail" class="pt-1">
                        <span class="only-day">{{diffDays}}</span>
                        <ic-flecha-pequena-arriba icon-color="#5d5d5d" v-if="showDetail"/>
                        <ic-flecha-pequena-abajo icon-color="#5d5d5d" v-else />
                    </div>
                </div>
            </div>
        </div>
        <div class="container d-md-none d-lg-none d-xl-none" v-if="showDetail">
            <div class="row">
                <div class="col-12 box-header-mobile">
                    <div class="row">
                        <div class="col-10 mt-2">
                            FECHAS
                        </div>
                        <div class="col-2 mt-1">
                            <ic-flecha-pequena-arriba icon-color="#5d5d5d"/>
                        </div>
                    </div>
                </div>
                <div class="col-6 mt-3">
                    <div class="container">
                        <div class="row mt-1">
                            <div class="col-2">
                                <ic-calendario-flecha-derecha iconColor="#fe3a00"/>
                            </div>
                            <div class="col-8 txt-bold-body mt-1">
                                Entrada
                            </div>
                            <div class="col-12 txt-body-normal">
                                {{booking.start_date}} {{booking.start_hour}}:{{booking.start_minute}}
                            </div>
                            <div class="col-12 txt-body-normal">
                                {{booking.area_pickup ? booking.area_pickup.name : ""}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-6 mt-3">
                    <div class="container">
                        <div class="row mt-1">
                            <div class="col-2">
                                <ic-calendario-flecha-izquierda iconColor="#fe3a00"/>
                            </div>
                            <div class="col-8 txt-bold-body mt-1">
                                Salida
                            </div>
                            <div class="col-12 txt-body-normal">
                                {{booking.end_date}} {{booking.end_hour}}:{{booking.end_minute}}
                            </div>
                            <div class="col-12 txt-body-normal">
                                {{booking.area_delivery ? booking.area_delivery.name : ""}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12  box-header-mobile">
                    <div class="row">
                        <div class="col-10 mt-2">
                            PARKING SELECCIONADO
                        </div>
                        <div class="col-2 mt-1">
                            <ic-flecha-pequena-arriba icon-color="#5d5d5d"/>
                        </div>
                    </div>
                </div>
                <div class="col-12 mt-3">
                    <div class="container">
                        <div class="row">
                            <div class="col-8 txt-bold-body">
                                {{booking.company ? booking.company.name : ""}}
                            </div>
                            <div class="col-4">
                                <div class="row">
                                    <div class="col-8 only-day">
                                        {{booking.company ? parseFloat(booking.company.total).toFixed(2) : ""}}€
                                    </div>
                                    <div class="col-4">
                                        <ic-circulo-lapiz iconColor="gray"/>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 txt-body-normal" v-html="booking.company ? booking.company.description : ''">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 box-header-mobile">
                    <div class="row">
                        <div class="col-10 mt-2">
                            TRASLADOS
                        </div>
                        <div class="col-2 mt-1">
                            <ic-flecha-pequena-arriba icon-color="#5d5d5d"/>
                        </div>
                    </div>
                </div>
                <div class="col-12 mt-3">
                    <div class="container">
                        <div class="row">
                            <div class="col-8 txt-bold-body">
                                {{booking.service ? booking.service.title : ""}}
                            </div>
                            <div class="col-4">
                                <div class="row">
                                    <div class="col-8 only-day">
                                        {{booking.service ? booking.service.price : ""}}€
                                    </div>
                                    <div class="col-4">
                                        <ic-circulo-lapiz iconColor="gray"/>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 mt-2 txt-body-normal" v-html="booking.service ? booking.service.description : ''">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 box-header-mobile">
                    <div class="row">
                        <div class="col-10 mt-2">
                            SEGUROS
                        </div>
                        <div class="col-2 mt-1">
                            <ic-flecha-pequena-arriba icon-color="#5d5d5d"/>
                        </div>
                    </div>
                </div>
                <div class="col-12 mt-3">
                    <div class="container">
                        <div class="row">
                            <div class="col-8 txt-bold-body">
                                {{booking.insurance ? booking.insurance.title : ""}}
                            </div>
                            <div class="col-4">
                                <div class="row">
                                    <div class="col-8 only-day">
                                        {{booking.insurance ? booking.insurance.price : ""}}€
                                    </div>
                                    <div class="col-4">
                                        <ic-circulo-lapiz iconColor="gray"/>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 txt-body-normal" v-html="booking.insurance ? booking.insurance.description : ''">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="menu-float d-none d-sm-block d-sm-none d-md-block" v-if="showDetail" style="background-color: #ededed; padding: 4px">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="row" >
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-3 title-header-desplegable">
                                        FECHAS
                                    </div>
                                    <div class="col-3 title-header-desplegable">
                                        PARKING SELECCIONADO
                                    </div>
                                    <div class="col-3 title-header-desplegable">
                                        TRASLADOS
                                    </div>
                                    <div class="col-3 title-header-desplegable">
                                        SEGUROS
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container d-none d-sm-block d-sm-none d-md-block" v-if="showDetail" style="margin-top:30px">
            <div class="col-12">
                <div class="row">
                    <div class="col-2">
                        <div class="row mt-1">
                            <div class="col-2">
                                <ic-calendario-flecha-derecha iconColor="#fe3a00"/>
                            </div>
                            <div class="col-8 txt-bold-body mt-1">
                                Entrada
                            </div>
                            <div class="col-12 txt-body-normal">
                                {{booking.start_date}} {{booking.start_hour}}:{{booking.start_minute}}
                            </div>
                            <div class="col-12 txt-body-normal">
                                {{booking.area_pickup ? booking.area_pickup.name : ""}}
                            </div>
                        </div>
                        <div class="row mt-1">
                            <div class="col-2">
                                <ic-calendario-flecha-izquierda iconColor="#fe3a00"/>
                            </div>
                            <div class="col-8 txt-bold-body mt-1">
                                Salida
                            </div>
                            <div class="col-12 txt-body-normal">
                                {{booking.end_date}} {{booking.end_hour}}:{{booking.end_minute}}
                            </div>
                            <div class="col-12 txt-body-normal">
                                {{booking.area_delivery ? booking.area_delivery.name : ""}}
                            </div>
                        </div>
                    </div>
                    <div class="col-1">
                        <div class="row">
                            <div class="col-6">
                            </div>
                            <div class="col-6">
                                <ic-circulo-lapiz iconColor="gray" height="30" width="30" style="margin-right:40px"/>
                            </div>
                        </div>
                        <div class="vl"></div>
                    </div>
                    <div class="col-2 mt-1">
                        <div class="row">
                            <div class="col-12 txt-bold-body">
                                {{booking.company ? booking.company.name : ""}}
                            </div>
                            <div class="col-12 mt-2 txt-body-normal" v-html="booking.company ? booking.company.description : ''">
                            </div>
                        </div>
                    </div>
                    <div class="col-1">
                        <div class="row">
                            <div class="col-8 only-day">
                                {{booking.company ? parseFloat(booking.company.total).toFixed(2) : ""}}€
                            </div>
                            <div class="col-4">
                                <ic-circulo-lapiz iconColor="gray"/>
                            </div>
                        </div>
                        <div class="vl"></div>
                    </div>
                    <div class="col-2 mt-1">
                        <div class="row">
                            <div class="col-12 txt-bold-body">
                                {{booking.service ? booking.service.title : ""}}
                            </div>
                            <div class="col-12 mt-2 txt-body-normal" v-html="booking.service ? booking.service.description : ''">
                            </div>
                        </div>
                    </div>
                    <div class="col-1">
                        <div class="row">
                            <div class="col-8 only-day">
                                {{booking.service ? booking.service.price : ""}}€
                            </div>
                            <div class="col-4">
                                <ic-circulo-lapiz iconColor="gray"/>
                            </div>
                        </div>
                        <div class="vl"></div>
                    </div>
                    <div class="col-2 mt-1">
                        <div class="row">
                            <div class="col-12 txt-bold-body">
                                {{booking.insurance ? booking.insurance.title : ""}}
                            </div>
                            <div class="col-12 mt-2 txt-body-normal" v-html="booking.insurance ? booking.insurance.description : ''">
                            </div>
                        </div>
                    </div>
                    <div class="col-1">
                        <div class="row">
                            <div class="col-8 only-day">
                                {{booking.insurance ? booking.insurance.price : ""}}€
                            </div>
                            <div class="col-4">
                                <ic-circulo-lapiz iconColor="gray"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import IcCirculoLapiz from "@/components/Icons/IcCirculoLapiz.vue"
import IcFlechaPequenaAbajo from "@/components/Icons/IcFlechaPequenaAbajo.vue"
import IcFlechaPequenaArriba from "@/components/Icons/IcFlechaPequenaArriba.vue"
import IcCalendarioFlechaDerecha from "@/components/Icons/IcCalendarioFlechaDerecha.vue"
import IcCalendarioFlechaIzquierda from "@/components/Icons/IcCalendarioFlechaIzquierda.vue"

export default {
    name: 'ResumeBookingHeader',
    components: {
        IcCirculoLapiz,
        IcFlechaPequenaAbajo,
        IcFlechaPequenaArriba,
        IcCalendarioFlechaDerecha,
        IcCalendarioFlechaIzquierda
    },
    data() {
        return {
            showDetail: false,
            booking: {
                id: null,
                invoice: {
                    id: null,
                    cif: null,
                    name: null,
                    city: null,
                    address: null,
                    postal_code: null,
                },
                pakage: {
                    id: null,
                    price: null,
                    title: null,
                    default: null,
                    discount: null,
                    features: null,
                    description: null,
                    icons: [],
                    sections: [],
                },
                code: null,
                place: null,
                status: null,
                arrival: null,
                code_qr: null,
                price_total: null,
                price_promo: null,
                price_insurance: null,
                is_drive: null,
                location: null,
                proceedings: null,
                flight_hour: null,
                flight_city: null,
                number_booking: null,
                flight_company: null,
                flight_country: null,
                terms_parking: null,
                terms_trasfer: null,
                terms_drivercar: null,
                passager_pickup: null,
                type_vehicle_id: null,
                passager_delivery: null,
                terms_parking_parking: null,
                flight_id: null,
                kms_pickup: null,
                code_manual: null,
                time_return: null,
                kms_delivery: null,
                manual_pickup: null,
                agent: null,
                user:{
                    dni: null,
                    name: null,
                    email: null,
                    phone: null,
                    color: null,
                    avatar: null,
                    message: null,
                    favorite: null,
                    last_name: null,
                    postal_code: null,
                    favorite: null,
                    favorite: null,
                },
                time_reservation: null,
                parking: {
                    id: null,
                    info: null,
                    name: null,
                    plaza: null,
                },
                vehicle: {
                    id: null,
                    model: null,
                    color: null,
                    status: null,
                    licenseplate: null,
                    brand: {
                        id: null,
                        name: null,
                    },
                },
                area_pickup: {
                    id: null,
                    name: null,
                },
                area_delivery: {
                    id: null,
                    name: null,
                },
                insurance: {
                    id: null,
                    title: null,
                },
            },
        }
    },
    methods: {
        onChangeShowDetail() {
            this.showDetail = !this.showDetail;
        },
    },
    computed: {
        diffDays() {
            if (this.booking.end_date != null && this.booking.start_date != null) {
                var endDate = moment(this.booking.end_date);
                var startDate = moment(this.booking.start_date);
                return endDate.diff(startDate, 'days') + " días";
            } else {
                return "";
            }
        }
    },
    mounted() {
        let booking = localStorage.getItem('booking');
        console.log(booking);
        this.booking = JSON.parse(booking);

    }
}
</script>

<style scoped>
.txt-body-normal {
    font-family: 'Raleway';
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #000;
}

.vl {
  border-right: 1px solid lightgray;
  height: 100%;
}

.txt-bold-body {
    font-family: 'Raleway';
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000;
}

.title-header-desplegable {
    color: #000;
    font-size: 12px;
    line-height: 1.67;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    font-family: 'Raleway';
    letter-spacing: normal;
}

.only-day {
    color: #fe3a00;
    font-size: 18px;
    text-align: right;
    font-weight: bold;
    font-style: normal;
    line-height: normal;
    font-stretch: normal;
    letter-spacing: normal;
    font-family: 'Raleway';
}

.menu-float {
    width:100%;
    z-index: 999;
    position:absolute;
    background-color: white;
}

.label-header {
    color: #000;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    line-height: normal;
    font-stretch: normal;
    letter-spacing: normal;
    font-family: 'Raleway';
}

.box-custom {
    background-color: #fff;
    box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.15);
}

.box-header-mobile {
    height: 32px;
    background-color: #ededed;
    font-family: 'Raleway';
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: #000;
}

</style>
