<template>
    <div class="sub-menu">
        <div class="container">
            <div class="row justify-content-md-center">
                <div class="col-1 text-center">
                    <div class="row">
                        <div class="col-12 pt-3 ps-2">
                            <ic-calendar :icon-color="isActiveIcon(1)" 
                                height="23" width="23" class="text-center"/>
                        </div>
                        <div class="col-12 text-center">
                            <div :class="isActive(dates)" v-if='!isEditing'>{{translate.home.submenu[0]}}</div>
                            <label class="item-text" v-else v-on:dblclick="showEdit = true, indexEdit = 0">
                                {{translate.home.submenu[0]}}
                            </label> 
                        </div>
                    </div>
                </div>
                <div class="col-1">
                    <hr class="divider"/>
                </div>
                <div class="col-1 text-center">
                    <div class="row">
                        <div class="col-12 pt-3">
                            <div>
                                <ic-parking :icon-color="isActiveIcon(2)" height="23" width="23" />
                            </div>
                            <div :class="isActive(parkings)" v-if='!isEditing'>{{translate.home.submenu[1]}}</div>
                            <label class="item-text" v-else v-on:dblclick="showEdit = true, indexEdit = 1">
                                {{translate.home.submenu[1]}}
                            </label> 
                        </div>
                    </div>
                </div>
                <div class="col-1">
                    <hr class="divider"/>
                </div>
                <div class="col-1 text-center">
                    <div class="row">
                        <div class="col-12 pt-3">
                            <div>
                                <ic-iidentificacion :icon-color="isActiveIcon(3)" height="23" width="23" />
                            </div>
                            <div :class="isActive(register)" v-if='!isEditing'>{{translate.home.submenu[2]}}</div>
                            <label class="item-text" v-else v-on:dblclick="showEdit = true, indexEdit = 2">
                                {{translate.home.submenu[2]}}
                            </label> 
                        </div>
                    </div>
                </div>
                <div class="col-1">
                    <hr class="divider"/>
                </div>
                <div class="col-1 text-center">
                    <div class="row">
                        <div class="col-12 pt-3">
                            <div>
                                <ic-autobus :icon-color="isActiveIcon(4)" height="23" width="23" />
                            </div>
                            <div :class="isActive(transfer)" v-if='!isEditing'>{{translate.home.submenu[3]}}</div>
                            <label class="item-text" v-else v-on:dblclick="showEdit = true, indexEdit = 3">
                                {{translate.home.submenu[3]}}
                            </label> 
                        </div>
                    </div>
                </div>
                <div class="col-1">
                    <hr class="divider"/>
                </div>
                <div class="col-1 text-center">
                    <div class="row">
                        <div class="col-12 pt-3">
                            <div>
                                <ic-escudo-candado :icon-color="isActiveIcon(5)" height="23" width="23" />
                            </div>
                            <div :class="isActive(insurance)" v-if='!isEditing'>{{translate.home.submenu[4]}}</div>
                            <label class="item-text" v-else v-on:dblclick="showEdit = true, indexEdit = 4">
                                {{translate.home.submenu[4]}}
                            </label> 
                        </div>
                    </div>
                </div>
                <div class="col-1">
                    <hr class="divider"/>
                </div>
                <div class="col-1 text-center">
                    <div class="row">
                        <div class="col-12 pt-3">
                            <div>
                                <ic-escudo-candado :icon-color="isActiveIcon(5)" height="23" width="23" />
                            </div>
                            <div :class="isActive(insurance)" v-if='!isEditing'>Servicios</div>
                            <label class="item-text" v-else v-on:dblclick="showEdit = true, indexEdit = 4">
                                Servicios
                            </label> 
                        </div>
                    </div>
                </div>
                <div class="col-1">
                    <hr class="divider"/>
                </div>
                <div class="col-1 text-center">
                    <div class="row">
                        <div class="col-12 pt-3">
                            <div>
                                <ic-documentos-check :icon-color="isActiveIcon(6)" height="23" width="23" />
                            </div>
                            <div :class="isActive(confirm)" v-if='!isEditing'>{{translate.home.submenu[5]}}</div>
                            <label class="item-text" v-else v-on:dblclick="showEdit = true, indexEdit = 5">
                                {{translate.home.submenu[6]}}
                            </label> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <b-modal v-model="showEdit" title="Editar Text" hide-footer>
        <div class="row">
            <div class="col-12">
                <input type="text" class="form-control p-2 mb-2" v-model="translate.home.submenu[indexEdit]">
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-6 text-center">
                        <b-button @click="onCloseEdit">Cancelar</b-button>
                    </div>
                    <div class="col-6 text-center">
                        <b-button @click="saveTranslate">Guardar</b-button>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
import store from '../store'
import {mapState} from 'vuex'
import IcParking from "./Icons/IcParking.vue"
import IcAutobus from "./Icons/IcAutobus.vue"
import IcCalendar from "./Icons/IcCalendar.vue"
import IcEscudoCandado from "./Icons/IcEscudoCandado.vue"
import IcIidentificacion from "./Icons/IcIidentificacion.vue"
import IcDocumentosCheck from "./Icons/IcDocumentosCheck.vue"

export default {
    name: 'SubMenu',
    computed: {
        ...mapState(['translate', 'isEditing'])
    },
    data() {
        return {
            edit: {
                submenu: [false],
            },
            indexEdit: 0,
            showEdit: false,
        }
    },
    props: {
        onSelect: {
            type: Number,
            default: 1
        },
        dates: {
            type: Boolean,
            default: false
        },
        parkings: {
            type: Boolean,
            default: false
        },
        register: {
            type: Boolean,
            default: false
        },
        transfer: {
            type: Boolean,
            default: false
        },
        insurance: {
            type: Boolean,
            default: false
        },
        confirm: {
            type: Boolean,
            default: false
        },
    },
    components: {
        IcAutobus,
        IcParking,
        IcCalendar,
        IcEscudoCandado,
        IcIidentificacion,
        IcDocumentosCheck,
    }, 
    methods: {
        saveTranslate(value) {
            const self = this;
            store.dispatch('saveTranslate');
            self.showEdit = false;
        },
        onCloseEdit() {
            const self = this;
            self.showEdit = false;
        },
        isActive(value) {
            return  value ? 'item-text-active' : 'item-text';
        },
        isActiveIcon(value) {
            return  this.onSelect == value  ? '#fe3a00' : 'gray';
        }
    }
}
</script>

<style>

.item-text-active {
    color: #fff;
    margin-top: 5px;
    font-size: 10px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    font-family: 'Raleway';
}

.item-text {
    color: gray;
    text-align: center;
    margin-top: 5px;
    font-family: 'Raleway';
    font-size: 10px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}

.divider {
    margin: 20px 0px;
    border-top: 2px dotted  white;
}

.sub-menu {
    z-index: 3;
    width: 100%;
    height: 77px;
    position:absolute;
    background: rgba(0, 0, 0, 0.5);
}

.sub-menu > div > div{
    height: 77px;
}
</style>
