<template>
    <div class="home">
        <NavBar/>
        <div style="position:relative; height: 77px; background-color: black">
            <SubMenu parkings="true" on-select="2"/>
        </div>
        <ResumeBookingHeader/>
        <div class="header-support">
            <div class="container">
                <div class="row">
                    <div class="col-12 text-center" style="margin-top: 37px" v-html="translate.parkings.title" v-on:dblclick="setEditTitle" />
                    <div class="col-12 text-center" style="margin-top: 16px">
                        <span class="header-body" v-html="translate.parkings.description" v-on:dblclick="setEditDescription" />
                    </div>
                    <div class="col-12 text-center" style="margin-top: 32px">
                        <span class="-parkings-encontrad">
                            {{items.length}} parkings encontrados
                        </span>
                    </div>
                    <div class="col-12 text-center" style="margin-top: 32px">
                        &nbsp;
                    </div>
                    <div class="col-12">
                        <div class="row justify-content-md-center">
                            <div class="col-12 col-sm-9 col-md-4" v-for="(item, index) in items" :key="index">
                                <CardParking 
                                    :company="item" 
                                    @onMoreInfo="onMoreInfo" 
                                    @onSelectParking="onSelectParking"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="col-12 text-center" style="margin-top: 32px">
                        &nbsp;
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
    <b-modal v-model="showEdit" title="Editar Text" hide-footer>
        <div class="row">
            <div class="col-12">
                <input type="text" class="form-control p-2 mb-2" v-model="translate.parkings.description">
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-6 text-center">
                        <b-button @click="onCloseEdit">Cancelar</b-button>
                    </div>
                    <div class="col-6 text-center">
                        <b-button @click="saveTranslate">Guardar</b-button>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
    <b-modal v-model="showEditTexarea" title="Editar Text" hide-footer>
        <div class="row">
            <div class="col-12">
                <textarea v-model="translate.parkings.title"  rows="10" cols="44"/>
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-6 text-center">
                        <b-button @click="onCloseEdit">Cancelar</b-button>
                    </div>
                    <div class="col-6 text-center">
                        <b-button @click="saveTranslate">Guardar</b-button>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import store from '../store'
import {mapState} from 'vuex'
import Footer from '@/components/Footer.vue'
import NavBar from '@/components/NavBar.vue'
import SubMenu from '@/components/SubMenu.vue'
import SubFooter from '@/components/SubFooter.vue'
import CardParking from '@/components/CardParking.vue'
import IcEstrella from '@/components/Icons/IcEstrella.vue'
import IcInformacion from '@/components/Icons/IcInformacion.vue'
import ResumeBookingHeader from '@/components/ResumeBookingHeader.vue'
import IcCirculoFlechaDerecha from '@/components/Icons/IcCirculoFlechaDerecha.vue'

export default {
    name: 'ParkingView',
    data() {
        return {
            items: [],
            parking_id: "",
            showEdit: false,
            showEditTexarea: false,
        }
    }, 
    components: {
        Footer,
        NavBar,
        SubMenu,
        SubFooter,
        IcEstrella,
        CardParking,
        IcInformacion,
        ResumeBookingHeader,
        IcCirculoFlechaDerecha,
    },
    methods: {
        saveTranslate() {
            const self = this;
            store.dispatch('saveTranslate');
            self.showEdit = false;
            self.showEditTexarea = false;
        },
        onCloseEdit() {
            const self = this;
            self.showEdit = false;
            self.showEditTexarea = false;
        },
        setEditDescription() {
            const self = this;
            if (self.isEditing) { 
                self.showEdit = true; 
            }
        },
        setEditTitle() {
            console.log("entra");
            const self = this;
            if (self.isEditing) { 
                self.showEditTexarea = true; 
            }
        },
        onMoreInfo(parking) {
            this.$router.push(`/parkings/${parking.id}/information`);
        },
        onSelectParking(parking) {
            let booking = localStorage.getItem('booking');
            const objBooking = JSON.parse(booking);

            var endDate = moment(objBooking.end_date);
            var startDate = moment(objBooking.start_date);
            var daysDiff = endDate.diff(startDate, 'days');

            if (parking.discount > 0) {
                var promoDiscount = (parking.price*(parking.discount/100)); 
                var pricePromo = (parking.price - promoDiscount);
                parking.total = pricePromo * daysDiff;
            } else {
                parking.total = parking.price * daysDiff;
            }

            objBooking.company_id = parking.id;
            objBooking.company = parking;
            console.log("bookingJson", objBooking);
            const bookingJson = JSON.stringify(objBooking);
            localStorage.setItem('booking', bookingJson);
            this.$router.push('/clients/register')
        },
        getParkings() {
            const self = this;
            axios.get('/companies/valets/web')
                .then(response => {
                    self.items = response.data.data;
                }).catch(() => {
                    console.error("Error")
                });
        }
    },
    computed: {
        ...mapState(['company', 'translate', 'isEditing'])
    },
    mounted() {
        const booking = localStorage.getItem('booking');
        if (!booking) {
            this.$router.push('/')
        }
        this.getParkings()
    }
}
</script>

<style scoped> 
.header-body {
    color: black;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.14;
    font-style: normal;
    text-align: center;
    font-stretch: normal;
    letter-spacing: normal;
    font-family: 'Raleway';
}

.header-text {
    color: black;
    font-size: 36px;
    font-weight: 800;
    text-align: center;
    font-style: normal;
    line-height: normal;
    font-stretch: normal;
    letter-spacing: normal;
    font-family: 'Raleway';
}

.header-text > span {
    color: #fe3a00;
}

.header-support {
    background-color: rgba(0, 0, 0, 0.15);
}
</style>
