<template>
    <div class="footer-background d-none d-md-block">
        <div class="container">
            <div class="row" >
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="row">
                        <div class="col-12 text-center" 
                            style="margin-top:67px; margin-bottom: 22px">
                            <ic-24h-flecha-grande icon-color="white" />
                        </div>
                        <div class="col-12">
                            <div class="footer-info-title text-center" v-on:dblclick="setEditTitle(0)">
                                {{translate.body.subfooter[0].title}}
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="footer-info-body text-center" v-on:dblclick="setEditDescription(0)">
                                {{translate.body.subfooter[0].description}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="row">
                        <div class="col-12 text-center" 
                            style="margin-top:67px; margin-bottom: 22px">
                            <ic-autobus-grande icon-color="white" />
                        </div>
                        <div class="col-12">
                            <div class="footer-info-title text-center" v-on:dblclick="setEditTitle(1)">
                                {{translate.body.subfooter[1].title}}
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="footer-info-body text-center" v-on:dblclick="setEditDescription(1)">
                                {{translate.body.subfooter[1].description}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="row">
                        <div class="col-12 text-center" 
                            style="margin-top:67px; margin-bottom: 22px">
                            <img src="../assets/images/logo.png" 
                                alt="slider1" class="img-slider" height="45">
                        </div>
                        <div class="col-12">
                            <div class="footer-info-title text-center" v-on:dblclick="setEditTitle(2)">
                                {{translate.body.subfooter[2].title}}
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="footer-info-body text-center" v-on:dblclick="setEditDescription(2)">
                                {{translate.body.subfooter[2].description}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="row">
                        <div class="col-12 text-center" 
                            style="margin-top:67px; margin-bottom: 22px">
                            <ic-telefonoic-grande icon-color="white" />
                        </div>
                        <div class="col-12">
                            <div class="footer-info-title text-center" v-on:dblclick="setEditTitle(3)">
                                {{translate.body.subfooter[3].title}}
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="footer-info-body text-center" v-on:dblclick="setEditDescription(3)">
                                {{translate.body.subfooter[3].description}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-background d-md-none">
        <div class="container">
            <div class="row">
                <div class="col-4 text-center p-5">
                    <ic-24h-flecha-grande icon-color="white" />
                </div>
                <div class="col-8 pt-5">
                    <div class="row text-left">
                        <div class="col-12">
                            <div class="footer-info-title" v-on:dblclick="setEditTitle(0)">
                                {{translate.body.subfooter[0].title}}
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="footer-info-body" v-on:dblclick="setEditDescription(0)">
                                {{translate.body.subfooter[0].description}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-4 text-center p-5">
                    <ic-autobus-grande icon-color="white" />
                </div>
                <div class="col-8 pt-5">
                    <div class="row text-left">
                        <div class="col-12">
                            <div class="footer-info-title" v-on:dblclick="setEditTitle(1)">
                                {{translate.body.subfooter[1].title}}
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="footer-info-body" v-on:dblclick="setEditDescription(1)">
                                {{translate.body.subfooter[1].description}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-4 text-center p-5">
                    <ic-conductor-grande icon-color="white" />
                </div>
                <div class="col-8 pt-5">
                    <div class="row text-left">
                        <div class="col-12">
                            <div class="footer-info-title" v-on:dblclick="setEditTitle(2)">
                                {{translate.body.subfooter[2].title}}
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="footer-info-body" v-on:dblclick="setEditDescription(2)">
                                {{translate.body.subfooter[2].description}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-4 text-center p-5">
                    <ic-telefonoic-grande icon-color="white" />
                </div>
                <div class="col-8 pt-5">
                    <div class="row text-left">
                        <div class="col-12">
                            <div class="footer-info-title" v-on:dblclick="setEditTitle(3)">
                                {{translate.body.subfooter[3].title}}
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="footer-info-body" v-on:dblclick="setEditDescription(3)">
                                {{translate.body.subfooter[3].description}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <b-modal v-model="showEditTitle" title="Editar Text" hide-footer>
        <div class="row">
            <div class="col-12">
                <input type="text" class="form-control p-2 mb-2" v-model="translate.body.subfooter[indexEdit].title">
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-6 text-center">
                        <b-button @click="onCloseEdit">Cancelar</b-button>
                    </div>
                    <div class="col-6 text-center">
                        <b-button @click="saveTranslate">Guardar</b-button>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
    <b-modal v-model="showEditDescription" title="Editar Text" hide-footer>
        <div class="row">
            <div class="col-12">
                <input type="text" class="form-control p-2 mb-2" v-model="translate.body.subfooter[indexEdit].description">
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-6 text-center">
                        <b-button @click="onCloseEdit">Cancelar</b-button>
                    </div>
                    <div class="col-6 text-center">
                        <b-button @click="saveTranslate">Guardar</b-button>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
import store from '../store'
import {mapState} from 'vuex'
import IcAutobusGrande from './Icons/IcAutobusGrande.vue'
import Ic24hFlechaGrande from './Icons/Ic24hFlechaGrande.vue'
import IcConductorGrande from './Icons/IcConductorGrande.vue'
import IcTelefonoicGrande from './Icons/IcTelefonoicGrande.vue'

export default {
    name: 'HelloWorld',
    components: {
        IcAutobusGrande,
        IcConductorGrande,
        Ic24hFlechaGrande,
        IcTelefonoicGrande,
    },
    data() {
        return {
            indexEdit: 0,
            showEditTitle: false,
            showEditDescription: false,
        }
    },
    methods: {
        setEditTitle(index) {
            if (this.isEditing) { 
                this.indexEdit = index;
                this.showEditTitle = true; 
            }
        },
        setEditDescription(index) {
            if (this.isEditing) { 
                this.indexEdit = index;
                this.showEditDescription = true; 
            }
        },
        saveTranslate() {
            const self = this;
            store.dispatch('saveTranslate');
            self.showEditTitle = false;
            self.showEditDescription = false;
        },
        onCloseEdit() {
            const self = this;
            self.showEditTitle = false;
            self.showEditDescription = false;
        },
    },
    computed: {
        ...mapState(['translate', 'isEditing'])
    }
}
</script>

<style scoped>
.footer-background {
    background-size: cover;
    background-color: #c2c2c2;
    background-repeat: no-repeat;
    background-image: url(../assets/images/img_bg_trama_triangulos.jpg);
}

.footer-info-body {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.14;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    font-family: 'Raleway';
}

.text-style-1 {
    font-size: 11px;
    font-weight: 800;
}

.footer-info-title {
    color: #fe3a00;
    font-size: 24px;
    line-height: 1;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    font-family: 'Raleway';
}

.footer-background {
    min-height: 300px;
    background-color:#000000;
}
</style>
